import React, { useState, useEffect, ChangeEvent } from 'react';
import logo from './MindwayLogo.svg';
import './App.css';

function App_en() {
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [result, setResult] = useState('');

  const questions = [
    "Gender", 
    "Age", 
    "Medical History", 
    "Complaints", 
    "Objective Findings",
  ];

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     if (Object.keys(inputValues).length > 0) {
  //       sendAnalyzes();
  //     }
  //   }, 250)

  //   return () => clearTimeout(delayDebounceFn)
  // }, [inputValues])

  // async function sendAnalyzes1() {
	// const xhr = new XMLHttpRequest();

  // setResult("Waiting...");

	// xhr.open("POST", "http://127.0.0.1:8080/get_prediction");
	// xhr.setRequestHeader("Content-Type", "application/json");
	// xhr.send(JSON.stringify({
  //       ...inputValues
  //   }));

  //   xhr.onload = () => {
  //     if (xhr.readyState === 4 && xhr.status === 200) {
  //       setResult("Diagnosis: " + xhr.responseText);
  //     } else {
  //       console.log(`Error: ${xhr.status}`);
  //       setResult('Diagnosis: Unknown');
  //     }
  //   };
  // }

  const sendAnalyzes = async () => {
    setResult("Waiting...");
    try {
      const response = await fetch('/get_prediction', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...inputValues
        })
    });
    
    if (!response.ok) {
      console.log(response);
      throw new Error('Network response was not ok');
    }

    const result = await response.text();
    setResult("Diagnosis: " + result);
    } catch (error) {
      console.error('Error:', error);
      setResult('Diagnosis: Unknown');
    }
  }
  
  const getRandomProtocol = async () => {
    setResult('');
    try {
        const response = await fetch('/get_random_protocol', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const protocol = await response.json();

        setInputValues(protocol);
    } catch (error) {
        console.error('Error:', error);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value
    });
  };

  function handleFetchRandomProtocol() {
    getRandomProtocol();
  }

  function updateTextAreas() {
    const answers = document.getElementsByClassName("answer");
    Array.from(answers).forEach (answer => {
      const textArea = answer as HTMLTextAreaElement;
      textArea.value = inputValues[textArea.name] || '';
      changeTextareaHeight(textArea);
    })
  }
  
  function handleClear() {
    setResult('');
    setInputValues(
      questions?.reduce((obj: Record<string, string>, question) => {
        obj[question] = '';
        return obj;
      }, {}) || {}
    );
  };

  function changeTextareaHeight(element: HTMLTextAreaElement) {
    element.style.height = "1px";
    element.style.height = (element.scrollHeight) + "px";
  }

  // TODO: such updates may slow you when you type
  // Remove after getting rid of "loadRandom" button
  updateTextAreas();

  window.onload=updateTextAreas;
  window.onresize=updateTextAreas;

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <div className='info'>
      <div className='buttons'>
		  <button className='rnd'   onClick={handleFetchRandomProtocol}>Randomize</button>
		  <button className='clear' onClick={handleClear}>Clear</button>
      </div>
      <div id="questions" className='questions'>
        {questions && questions.map((question) => (
        <div key={question} className='text-with-label'>
          <label htmlFor={question}>{question}</label>
          <textarea 
            id={question} 
            name={question} 
            className='answer' 
            onInput={(event) => {changeTextareaHeight(event.target as HTMLTextAreaElement); if(result != '') setResult('')}} 
            onChange={handleInputChange}
          />
        </div>
        ))}
      </div>
      <div>
		    <button onClick={sendAnalyzes}>Predict</button>
        <div className='prediction'>{result}</div>
      </div>
      </div>
      </header>
    </div>
  );
}

export default App_en;
